import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import ProjectModelTableItem from "@/entity/project/ProjectModelTableItem";
import { ModelObjectOptions, ModelTypeOptions } from "@/entity/project/ProjectControl";
@Options({
    name: "app-project-model-tr",
    props: {
        modelValue: {
            type: [ProjectModelTableItem, Object],
            default: (): ProjectModelTableItem => null,
        },
    },
    emits: ["update:modelValue", "click:addModelOpen", "click:modModelOpen", "click:addAttributeOpen", "ActionEvent"],
})
export default class ModelTr extends BaseFunction {
    private modelValue!: ProjectModelTableItem;

    get node() {
        return this.modelValue;
    }
    set node(val) {
        this.$emit("update:modelValue", val);
    }

    get modelTypeName(): string {
        return ModelTypeOptions.find((item: any) => item.value === this.node.modelType)?.label;
    }
    get modelObjectName(): string {
        return ModelObjectOptions.find((item: any) => item.value === this.node.modelObject)?.label;
    }
    get modelTypeNameCommon(): string {
        return this.node.modelType == "class" ? "公共" : ModelTypeOptions.find((item: any) => item.value === this.node.modelType)?.label;
    }
    get childrenLength(): number {
        return this.$tools.isEmpty(this.node.children) ? 0 : this.node.children.length;
    }
    get attributeLength(): number {
        return this.$tools.isEmpty(this.node.attributeJson) ? 0 : this.node.attributeJson.length;
    }
    get modelExpandClass(): any {
        return this.node.modelExpand ? "rotate-clockwise" : "rotate-anticlockwise";
    }
    get attributeExpandClass(): any {
        return this.node.attributeExpand ? "rotate-clockwise" : "rotate-anticlockwise";
    }
    get isChildren(): boolean {
        return this.node.childrenCount > 0;
    }

    //展开收起设备
    public toggleModel(actionEvent: Function, node: ProjectModelTableItem, event: any) {
        if (!node || !node.childrenCount || node.childrenCount < 1) return;
        if (node.modelExpand) {
            node.children = [];
            node.modelExpand = false;
        } else {
            actionEvent("ExpandModel", node, 0, event);
            node.modelExpand = true;
        }
    }
    //填充层级
    public fillTree(parentStr: string, str: string): string {
        // const matches = parentStr.match(/-/g);
        // const count = matches ? matches.length : 0;
        // const level = parentStr.length < 1 ? 0 : count + 1;
        // const fgf = "-".repeat(level * 2);
        // return `|--${fgf}${str}`;
        if (this.$tools.isEmpty(parentStr)) {
            return str;
        } else {
            return `${parentStr}-${str}`;
        }
    }
    //动作事件
    public actionEvent(action: string, node: ProjectModelTableItem, index: number, event: any) {
        this.$emit("ActionEvent", action, node, index, event);
    }
}
